// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._3cIIU{}\n\n._3cIIU > div {\n    margin-bottom: 16px;\n}\n\n._3cIIU > div:last-child {\n    margin-bottom: 0;\n}\n\n._2d6vy{\n    font-size: 14px;\n    line-height: 20px;\n    color: var(--font-color);\n    letter-spacing: normal;\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"post": "_3cIIU",
	"content": "_2d6vy"
};
module.exports = ___CSS_LOADER_EXPORT___;
