// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".gME-l {}\n\n.gME-l._3yEOS {\n    visibility: hidden;\n}\n\n.gME-l * {\n    -webkit-box-sizing: border-box;\n            box-sizing: border-box;\n}\n\n.gME-l ._22cF5{\n    font-size: 14px;\n    line-height: 20px;\n    color: var(--font-color);\n    letter-spacing: normal;\n}\n\n.gME-l h1, .gME-l h2, .gME-l h3, .gME-l h4, .gME-l h5, .gME-l h6, .gME-l strong {\n    font-weight: 500;\n    color: var(--font-color);\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dialog": "gME-l",
	"hidden": "_3yEOS",
	"dialogContent": "_22cF5"
};
module.exports = ___CSS_LOADER_EXPORT___;
