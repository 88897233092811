// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._22k0y {\n    position: relative;\n}\n\n._22k0y._338aT {}\n\n._22k0y.mNF9r {\n    padding-bottom: 56%;\n    background-color: var(--divider-color);\n}\n\n._22k0y a {\n    display: block;\n}\n\n._22k0y ._3gM_c{\n    width: 100%;\n    height: auto;\n    vertical-align: top;\n    -webkit-transition: all 1000ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;\n    -o-transition: all 1000ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;\n    transition: all 1000ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;\n}\n\n._22k0y._338aT ._3gM_c{}\n\n._22k0y.mNF9r ._3gM_c{\n    display: none;\n}\n\n._22k0y > *:nth-last-child(2) {\n    border-bottom-right-radius: 8px;\n    border-bottom-left-radius: 8px;\n    overflow: hidden;\n}\n\n._22k0y ._3JE8T{\n    display: none;\n}\n\n._22k0y ._3JE8T svg {\n    width: 56px;\n    height: 56px;\n}\n\n._22k0y.mNF9r ._3JE8T{\n    display: -ms-flexbox;\n    display: flex;\n    -ms-flex-align: center;\n        align-items: center;\n    -ms-flex-pack: center;\n        justify-content: center;\n    height: 100%;\n    width: 100%;\n    position: absolute;\n    top: 0;\n    left: 0;\n}\n\n._22k0y ._2GOO0 {\n    min-height: 128px;\n    padding: 32px;\n}\n\n._22k0y ._2GOO0._11v4x {\n    padding: 0;\n}\n\n._22k0y._3vAwf > div{\n    border-bottom-right-radius: 8px;\n    border-bottom-left-radius: 8px;\n    overflow: hidden;\n}\n\n@media (max-width:599px) {\n    ._22k0y ._2GOO0{\n        padding: 16px;\n    }\n    ._22k0y ._2GOO0._11v4x{\n        padding: 0;\n    }\n}\n\n@media (prefers-color-scheme: dark) {}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"post": "_22k0y",
	"loaded": "_338aT",
	"error": "mNF9r",
	"image": "_3gM_c",
	"noImageIconContainer": "_3JE8T",
	"content": "_2GOO0",
	"noPadding": "_11v4x",
	"thereIsNotContent": "_3vAwf"
};
module.exports = ___CSS_LOADER_EXPORT___;
