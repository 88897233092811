import React, {useContext, useEffect, useLayoutEffect, useState, useRef, useMemo} from "react";
import {WappContext, withWapp} from "wapplr-react/dist/common/Wapp";
import getUtils from "wapplr-react/dist/common/Wapp/getUtils";
import Log from "wapplr-react/dist/common/Log";
import DefaultLogo from "wapplr-react/dist/common/Logo";

import clsx from "clsx";

import MaterialDrawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";

import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from '@material-ui/icons/Close';

import {storage as defaultLocalStorage} from "../../utils/localStorage";

import AppContext from "../App/context";
import Menu from "../Menu";

import {withMaterialTheme, withMaterialStyles} from "./withMaterial";
import {materialTheme, materialMediaQuery} from "./materialTheme";

import defaultStyle from "./style.css";
import materialStyle from "./materialStyle";
import {storage as defaultMemoStorage} from "../../utils/memoStorage";

const containers = {};

function Template(props) {

    const context = useContext(WappContext);
    const appContext = useContext(AppContext);
    const utils = getUtils(context);

    const {wapp} = context;
    const {siteName = "Wapplr"} = wapp.config;

    const {

        /*style*/
        style = defaultStyle,
        materialStyle,
        position = "relative",
        fullscreen,
        breakPoint = 960,
        transparentAppBar = false,
        pageContentNoPadding = false,

        /*content*/
        title = "",
        children,
        Logo = DefaultLogo,
        logoHref = "/",
        FooterLogo,
        copyright = `${siteName} ${new Date().getFullYear()} ©`,
        getMenu = function () {return []},
        getTopMenu = function () {return []},
        getFooterMenu = function () {return []},
        menuProps = {},
        topMenuProps = {},
        footerMenuProps = {},

        /*utils*/
        subscribe,

    } = props;

    const appBarPosition = position;
    const fixedDrawer = (appBarPosition === "fixed");

    //const materialTheme = useTheme();

    wapp.styles.use(style);

    const {
        storage = function (data, memo) {
            if (memo){
                return defaultMemoStorage((data) ? data : {}, wapp.globals.NAME);
            }
            return defaultLocalStorage(data, wapp.globals.NAME);
        }
    } = appContext;

    if (!containers[wapp.globals.WAPP]){
        containers[wapp.globals.WAPP] = {};
    }

    const urlKey = wapp.client?.history.getState().key || "initial";

    const container = useRef();
    const drawerContainer = useRef();

    const initialState = (typeof window !== "undefined" && window[wapp.config.appStateName]) || {req:{timestamp: Date.now()}};
    const firstRender = (utils.getGlobalState("req.timestamp") === initialState.req.timestamp || wapp.target === "node");
    const scrollTop = storage(undefined, true)["scrollTop_"+urlKey] || 0;

    const [open, _setOpen] = useState((firstRender) ? false : storage().drawerOpen);
    const [sticky, _setSticky] = useState(scrollTop > 0);
    const [narrow, _setNarrow] = useState((!firstRender && typeof window !== "undefined" && containers[wapp.globals.WAPP].current) ? containers[wapp.globals.WAPP].current.offsetWidth < breakPoint : false);
    const [user, setUser] = useState(utils.getRequestUser());

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const menu = useMemo(()=>getMenu({appContext, context}), []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const topMenu = useMemo(()=>getTopMenu({appContext, context}), []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const footerMenu = useMemo(()=>getFooterMenu({appContext, context}), []);

    const reallyDoesOpen = !!(open && menu?.length);

    const handleDrawerClose = useMemo(()=> async function () {
        if (open) {
            storage({drawerOpen: false});
            await _setOpen(false);
        }
    }, [open, storage]);

    async function handleDrawerToggle() {
        storage({drawerOpen: !open});
        await _setOpen(!open);
    }

    const drawerCloseNarrow = useMemo(()=>
        async function () {
        if (narrow){
            await handleDrawerClose();
            await new Promise(resolve => setTimeout(resolve, 500));
        }
    }, [handleDrawerClose, narrow]);

    const storeDrawerScrollTop = useMemo(()=> function () {
        const drawerScrollTop = drawerContainer?.current?.parentElement?.scrollTop || 0;
        storage({drawerScrollTop: drawerScrollTop}, true);
    }, [storage]);

    const setLastDrawerScrollTop = useMemo(()=> function () {
        const drawerScrollTop = storage(undefined, true).drawerScrollTop || 0;
        if (drawerContainer?.current?.parentElement){
            drawerContainer.current.parentElement.scrollTop = drawerScrollTop;
        }
    }, [storage]);

    const storeScrollTop = useMemo(()=> function ({urlKey}) {
        const scrollTop = (appBarPosition === "sticky" && container.current) ? container.current.scrollTop : window.scrollY;
        if (container.current) {
            storage({["scrollTop_" + urlKey]: scrollTop}, true);
        }
    }, [appBarPosition, storage]);

    const setLastScrollTop = useMemo(()=> function ({urlKey}) {
        if (container.current) {

            const scrollTop = storage(undefined, true)["scrollTop_"+urlKey] || 0;

            if (appBarPosition === "sticky") {
                if (container.current) {
                    container.current.scrollTop = scrollTop;
                }
            } else if (appBarPosition === "fixed") {
                if (window.scrollY !== scrollTop) {
                    window.scrollTo(0, scrollTop)
                }
            }
        }

    }, [appBarPosition, storage]);

    const actions = useMemo(()=>{
        return {
            scrollTop: function () {
                setLastScrollTop({urlKey});
            },
            getState: function () {
                return {open, sticky, narrow}
            },
            getStyle: function (){
                return style || {}
            },
            drawerClose: handleDrawerClose,
            drawerCloseNarrow: drawerCloseNarrow,
            storeDrawerScrollTop,
            setLastDrawerScrollTop
        }
    }, [drawerCloseNarrow, handleDrawerClose, narrow, open, setLastDrawerScrollTop, setLastScrollTop, sticky, storeDrawerScrollTop, style, urlKey]);

    const onClick = useMemo(()=>async function (e, {href}) {
        if (href) {
            await drawerCloseNarrow();
            wapp.client.history.push({
                search: "",
                hash: "",
                ...wapp.client.history.parsePath(href)
            });
        }
        e.preventDefault();
        // eslint-disable-next-line
    }, [wapp, drawerCloseNarrow, actions]);

    if (wapp.target !== "node") {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useLayoutEffect(function () {
            setLastDrawerScrollTop();
            setLastScrollTop({urlKey});
        }, [setLastDrawerScrollTop, setLastScrollTop, urlKey])
    }

    useEffect(function didMount(){

        wapp.client.history.globalHistory.scrollRestoration = "manual";

        function onUserChange(user){
            setUser((user?._id) ? user : null);
        }

        function setOpen(value){
            if (open !== value){
                _setOpen(value)
            }
        }

        function setSticky(value){
            if (sticky !== value){
                _setSticky(value)
            }
        }

        function setNarrow(value){
            if (narrow !== value){
                _setNarrow(value)
            }
        }

        function onScroll(e) {
            if (appBarPosition === "sticky") {
                if (container.current) {
                    if (container.current.scrollTop > 0) {
                        setSticky(true);
                    } else {
                        setSticky(false);
                    }
                }
            } else if (appBarPosition === "fixed") {
                if (window.scrollY > 0) {
                    setSticky(true);
                } else {
                    setSticky(false);
                }
            } else {
                setSticky(false);
            }
            if (e) {
                storeScrollTop({urlKey})
            }
        }

        function onDrawerScroll() {
            const drawerScrollTop = drawerContainer?.current?.parentElement?.scrollTop || 0;
            storage({drawerScrollTop: drawerScrollTop}, true);
        }

        function onResize() {
            if (container.current) {
                if (container.current.offsetWidth > breakPoint) {
                    setNarrow(false);
                } else {
                    setNarrow(true);
                }
            } else {
                if (window.innerWidth > breakPoint) {
                    setNarrow(false);
                } else {
                    setNarrow(true);
                }
            }
        }

        function addScrollListeners() {
            if (appBarPosition === "sticky") {
                if (container.current) {
                    container.current.addEventListener("scroll", onScroll);
                    return function removeEventListener(){
                        if (container.current) {
                            container.current.removeEventListener("scroll", onScroll);
                        }
                    }
                }
            } else if (appBarPosition === "fixed") {
                window.addEventListener("scroll", onScroll);
                return function removeEventListener(){
                    window.removeEventListener("scroll", onScroll);
                }
            } else {
                return function removeEventListener(){}
            }
        }

        function addDrawerScrollListener() {
            const drawerElement = drawerContainer?.current?.parentElement;
            if (drawerElement){
                drawerElement.addEventListener("scroll", onDrawerScroll);
                return function removeEventListener(){
                    if (drawerElement) {
                        drawerElement.removeEventListener("scroll", onDrawerScroll);
                    }
                }
            }
            return function removeEventListener(){}
        }

        function addResizeListeners() {
            if (container.current && typeof ResizeObserver !== "undefined") {
                const resizeObserver = new ResizeObserver((entries) => {
                    onResize(entries);
                });
                resizeObserver.observe(container.current);
                return function removeEventListener(){
                    resizeObserver.disconnect();
                }
            } else {
                window.addEventListener("resize", onResize);
                return function removeEventListener(){
                    window.removeEventListener("resize", onResize);
                }
            }
        }

        const removeScrollListeners = addScrollListeners();
        const removeDrawerScrollListeners = addDrawerScrollListener();
        const removeResizeListeners = addResizeListeners();
        const unsub = subscribe.userChange(onUserChange);

        const storageDrawerOpen = storage().drawerOpen;
        if (typeof storageDrawerOpen == "boolean" && open !== storageDrawerOpen){
            setOpen(storageDrawerOpen);
        }

        onResize();
        onScroll();

        containers[wapp.globals.WAPP].current = container.current;

        if (props.effect){
            props.effect({
                actions: actions
            })
        }

        return function willUnmount() {

            removeScrollListeners();
            removeDrawerScrollListeners();
            removeResizeListeners();
            unsub();

            if (props.effect){
                props.effect(null);
            }

        }

    }, [actions, appBarPosition, breakPoint, narrow, open, props, sticky, storage, storeScrollTop, subscribe, urlKey, wapp.client?.history.globalHistory, wapp.globals.WAPP]);

    return (
        <div
            ref={container}
            className={
                clsx(
                    materialStyle.root,
                    style.root,
                    {[style.fullscreen]: fullscreen},
                    {[style.narrow]: narrow},
                    {[style.drawerOpen] : reallyDoesOpen},
                    {[style.transparentAppBar] : transparentAppBar},
                )
            }>
            <AppBar
                position={appBarPosition}
                className={
                    clsx(
                        materialStyle.appBar,
                        style.appBar,
                        {[style.appBarPositionSticky]: appBarPosition === "sticky"},
                        {[materialStyle.appBarSticky]: sticky},
                        {[style.appBarSticky]: sticky},
                    )}
            >
                <Toolbar className={style.toolbar}>
                    {(menu?.length) ?
                        <IconButton
                            color={"inherit"}
                            aria-label={"open drawer"}
                            onClick={handleDrawerToggle}
                            className={clsx(materialStyle.menuButton, style.menuButton)}
                        >
                            {(reallyDoesOpen) ? <CloseIcon/> : <MenuIcon/>}
                        </IconButton>
                        :
                        null
                    }
                    {
                        (Logo) ?
                            <div
                                className={clsx(
                                    style.logo,
                                    {[style.cursorPointer] : logoHref}
                                )}
                                onClick={(e) => onClick(e, {href: logoHref})}
                            >
                                <Logo />
                            </div>
                            : null
                    }
                    {
                        (title) ?
                            <div
                                className={clsx(
                                    style.title,
                                    {[style.cursorPointer] : logoHref}
                                )}
                                onClick={(e) => onClick(e, {href: logoHref})}
                            >
                                <Typography variant={"h6"} noWrap>
                                    {title}
                                </Typography>
                            </div>
                            :
                            <div
                                className={clsx(
                                    style.title,
                                    {[style.cursorPointer] : logoHref}
                                )}
                                onClick={(e) => onClick(e, {href: logoHref})}
                            />
                    }
                    {
                        (topMenu?.length) ?
                            <div className={style.topMenu}>
                                <Menu
                                    menu={topMenu}
                                    menuProperties={{user}}
                                    {...topMenuProps}
                                />
                            </div>
                            : null
                    }
                </Toolbar>
            </AppBar>
            <div className={style.mainContainer}>
                <MaterialDrawer
                    variant={"permanent"}
                    style={{marginLeft: reallyDoesOpen ? "0px" : "-320px"}}
                    className={clsx(
                        style.drawer,
                        {
                            [style.drawerNarrow]: narrow,
                            [style.drawerFixed]: fixedDrawer,
                            [materialStyle.drawerOpen]: reallyDoesOpen,
                            [materialStyle.drawerClose]: !reallyDoesOpen,
                            [style.drawerOpen]: reallyDoesOpen,
                            [style.drawerClose]: !reallyDoesOpen,
                        },
                    )}
                    classes={{
                        paper: clsx(
                            style.drawerPaper,
                            {
                                [style.drawerPaperFixed]: fixedDrawer,
                                [style.drawerPaperAbsoluteWithStickyAppBar]: !fixedDrawer && appBarPosition === "sticky",
                                [materialStyle.drawerOpen]: reallyDoesOpen,
                                [materialStyle.drawerClose]: !reallyDoesOpen,
                                [style.drawerOpen]: reallyDoesOpen,
                                [style.drawerClose]: !reallyDoesOpen,
                            }
                        )
                    }}
                    open={reallyDoesOpen}
                    PaperProps={{
                        style:{marginLeft: reallyDoesOpen ? "0px" : "-320px"},
                    }}
                    ModalProps={{
                        keepMounted: true,
                    }}
                >
                    <div
                        className={materialStyle.drawerContainer}
                        ref={drawerContainer}
                    >
                        <Divider />
                        {
                            (menu && menu.length) ?
                                <Menu
                                    menu={menu}
                                    menuProperties={{user}}
                                    list={true}
                                    {...menuProps}
                                />
                                : null
                        }
                    </div>
                </MaterialDrawer>
                <main className={clsx(
                    style.content,
                    {[style.narrowAndOpen]: narrow && reallyDoesOpen}
                )}>
                    <div
                        className={
                            clsx(
                                materialStyle.drawerLayer,
                                style.drawerLayer,
                                {[style.drawerLayerShow]: narrow && reallyDoesOpen}
                            )
                        }
                        onClick={handleDrawerClose}
                    />
                    <div className={style.page}>
                        {(appBarPosition === "fixed" && fixedDrawer) ? <div className={style.pagePaddingTop} /> : null}
                        <div className={clsx(
                            style.pageContent,
                            {[style.pageContentNoPadding] : pageContentNoPadding},
                        )} >
                            {children}
                        </div>
                    </div>
                    <footer className={style.footer}>
                        <div className={style.footerOneColumn}>
                            {
                                (footerMenu?.length) ?
                                    <div className={style.footerMenu}>
                                        <Menu
                                            menu={footerMenu}
                                            menuProperties={{user}}
                                            {...footerMenuProps}
                                        />
                                    </div>
                                    : null
                            }
                            {FooterLogo ?
                                <div className={style.footerLogo}>
                                    <FooterLogo />
                                </div>
                                :
                                null
                            }
                            <div className={style.copyright}>
                                {copyright}
                            </div>
                            {(wapp.globals.DEV) ?
                                <div className={style.log}>
                                    <Log Parent={null} Logo={null } />
                                </div> : null
                            }
                        </div>
                    </footer>
                </main>
            </div>
        </div>
    )
}

const WappComponent = withWapp(Template);

const StyledComponent = withMaterialStyles(materialStyle, WappComponent);

export default StyledComponent;

// noinspection JSUnusedGlobalSymbols
export const ThemedComponent = withMaterialTheme({theme: materialTheme, mediaQuery: materialMediaQuery}, StyledComponent);
