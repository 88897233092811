import React from "react";

const AccountContext = React.createContext({
    user:null,
    parentRoute:null,
    name:"user",
    page:"",
    statusManager:null,

    documentName: "document",
    termsSlug: "terms",
    privacySlug: "privacy",
    acceptTermsPropertyName: "acceptTerms",
    acceptPrivacyPropertyName: "acceptPrivacy",

});

export default AccountContext;
