// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._3b-7o{}\n\n._3b-7o ._23j8K{}\n\n._3b-7o ._18Hea{}\n\n._3b-7o .uPJEX{}\n\n._3b-7o ._2ROVB{\n    background-color: rgba(0, 0, 0, 0.04);\n}\n\n._3b-7o ._2kwOq{\n    overflow: hidden;\n    -o-text-overflow: ellipsis;\n       text-overflow: ellipsis;\n    display: -webkit-box;\n    line-clamp: 2;\n    -webkit-box-orient: vertical;\n    white-space: normal;\n}\n\n._3b-7o ._3luLO > div, ._3b-7o ._3luLO > div > span {\n    font-weight: 500;\n    color: var(--secondary-bg);\n}\n\n._3b-7o ._2b3U- {\n    position: absolute;\n    top: 0;\n    height: 100%;\n    left: 0;\n    width: 3px;\n    background-color: var(--secondary-bg);\n}\n\n._3b-7o .Dgl2T > div{\n    max-width: 24px;\n}\n\n._3b-7o ul hr:last-child {\n    display: none;\n}\n\n@media print {\n    ._3b-7o ._23j8K > span{\n        font-weight: 500;\n    }\n    ._3b-7o ._18Hea{\n        visibility: hidden!important;\n    }\n    ._3b-7o .uPJEX {\n        height: auto !important;\n        visibility: visible !important;\n    }\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"menu": "_3b-7o",
	"collapseButtonLabel": "_23j8K",
	"collapseIcon": "_18Hea",
	"collapseContainer": "uPJEX",
	"collapse": "_2ROVB",
	"listItemSecondaryText": "_2kwOq",
	"selectedMenu": "_3luLO",
	"selectedMark": "_2b3U-",
	"listItemIcon": "Dgl2T"
};
module.exports = ___CSS_LOADER_EXPORT___;
