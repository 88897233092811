import React, {useContext, useState, useEffect} from "react";

import {WappContext} from "wapplr-react/dist/common/Wapp";

import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import MaterialMoreIcon from "@material-ui/icons/MoreVert";
import MaterialMenu from "@material-ui/core/Menu";
import List from "@material-ui/core/List";
import MenuItem from "@material-ui/core/MenuItem";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";

import clsx from "clsx";

import AppContext from "../App/context";
import {withMaterialStyles} from "../Template/withMaterial";

import style from "./style.css";
import materialStyle from "./materialStyle"
import getUtils from "wapplr-react/dist/common/Wapp/getUtils";

function defaultSort(a, b) {
    const aO = a.order || 0;
    const bO = b.order || 0;
    return (aO > bO) ? 1 : (aO < bO) ? -1 : 0;
}

function GenerateMenuFunc(props) {

    const {appContext, context, store} = props;
    const {wapp, req} = context;
    const utils = getUtils(context);

    const {storage} = appContext;
    const {menu, ...rest} = props;
    const {ItemComponent, menuComponentProps, parentRoute, menuProperties, onClick, style, materialStyle, menuKey = "menu_0"} = rest;

    const initialState = (typeof window !== "undefined" && window[wapp.config.appStateName]) || {req:{timestamp: Date.now()}};
    const firstRender = (utils.getGlobalState("req.timestamp") === initialState.req.timestamp || wapp.target === "node");

    const [open, _setOpen] = useState((firstRender) ? false : store?.[menuKey] ? store[menuKey] : false);

    async function setOpen(value) {
        if (value !== open) {
            if (storage) {
                storage({[menuKey]: value});
            }
            await _setOpen(value);
        }
    }

    const name = (typeof menu.name == "function") ? menu.name(menuProperties) : menu.name;
    const target = menu.target || "self";
    const href = (typeof menu.href == "function") ? menu.href(menuProperties) : (menu.href) ? menu.href : "";
    const disableParentRoute = menu.disableParentRoute;
    const Icon = menu.Icon;
    const show = (menu.role) ? menu.role(menuProperties) : true;
    const inner = !(target === "_blank" || (href && href.slice(0,7) === "http://") || (href && href.slice(0,8) === "https://"));
    const paddingLeft = menu.paddingLeft;
    const className = (typeof menu.className == "function") ? menu.className({...menuProperties, style}) : menu.className;
    const menuProps =  (typeof menu.props == "function") ? menu.props({...menuProperties, style}) : menu.props;

    const {itemTextProps, itemProps} = menuProps || {};

    useEffect(()=>{
        const newOpenState = store?.[menuKey] ? store[menuKey] : false;
        if (newOpenState !== open){
            _setOpen(newOpenState)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (menu.divider && show){
        return (
            <Divider/>
        )
    }

    if (show) {

        const hrefProp = (inner) ? (disableParentRoute) ? href : parentRoute + href : href;

        const isSelected = (inner && hrefProp === req.wappRequest.path);

        const menuItems = menu.items?.length ? [...menu.items].sort(defaultSort) : [];

        return (
            <>
                <ItemComponent
                    ref={props.forwardedRef}
                    component={"a"}
                    target={target}
                    href={hrefProp}
                    onClick={async function (e) {
                        if (menu.items?.length){
                            e.preventDefault();
                            await setOpen(!open);
                            return;
                        }
                        await onClick(e, menu)
                    }}
                    className={clsx(
                        materialStyle.listItem,
                        {
                            [className]: className,
                            [style.selectedMenu]: isSelected
                        }
                    )}
                    {...(paddingLeft) ? {sx:{pl: paddingLeft}} : {}}
                    {...(itemProps) ? itemProps : {}}
                >
                    {(Icon) ?
                        <ListItemIcon className={clsx(materialStyle.listItemIcon, style.listItemIcon)}>
                            <Icon color={(isSelected) ? "secondary" : "initial"} />
                        </ListItemIcon> : null
                    }
                    <ListItemText
                        className={clsx({
                            [style.collapseButtonLabel]: (menu.items?.length)
                        })}
                        primary={name}
                        {...(itemTextProps) ? itemTextProps : {}}
                        secondary={(itemTextProps?.secondary) ? <div className={style.listItemSecondaryText}>{itemTextProps.secondary}</div> : null}
                    />
                    {(menu.items?.length) ?
                        <ListItemSecondaryAction className={style.collapseIcon}>
                            <IconButton edge={"end"} aria-label={"open"} onClick={()=>setOpen(!open)}>
                                {(open) ? <ExpandLess /> : <ExpandMore />}
                            </IconButton>
                        </ListItemSecondaryAction>
                        : null
                    }
                    {(isSelected) ? <div className={style.selectedMark}/> : null}
                </ItemComponent>
                {(menu.items?.length) ?
                    <Collapse in={open}  className={style.collapseContainer}>
                        <div className={style.collapse}>
                            <List {...menuComponentProps} {...{sx: { pl: (paddingLeft) ? paddingLeft : 0}, component:"div",  disablePadding:true}} >
                                {[...menuItems.map((m, k)=><GenerateMenu menu={m} key={"menu_collapse_item_" + k} {...rest} menuKey={menuKey+k}/>)]}
                            </List>
                        </div>
                    </Collapse>
                    :
                    null
                }
            </>
        )

    }

    return null;

}

const GenerateMenu = React.forwardRef((props, ref) => {
    return <GenerateMenuFunc {...props} forwardedRef={ref} />;
});

function Menu(props) {

    const context = useContext(WappContext);
    const appContext = useContext(AppContext);

    const {wapp} = context;
    const {/*template, */storage} = appContext;
    const store = storage();

    wapp.styles.use(style);

    const {
        parentRoute = "",
        materialStyle = {},
        list,
        effect,
        menuKey = "menu",
        MoreIcon = MaterialMoreIcon
    } = props;

    const menuProperties = {...(props.menuProperties) ? props.menuProperties : {}, appContext, context};

    const [anchorEl, setAnchorEl] = useState(null);

    const [menu, setMenu] = useState(props.menu || []);

    function handleMenuOpen (event) {
        setAnchorEl(event.currentTarget);
    }

    function handleMenuClose () {
        setAnchorEl(null);
    }

    async function onClick(e, menu) {

        const target = menu.target || "self";
        const href = (typeof menu.href == "function") ? menu.href(menuProperties) : (menu.href) ? menu.href : "";
        const disableParentRoute = menu.disableParentRoute;
        const inner = !(target === "_blank" || (href && href.slice(0,7) === "http://") || (href && href.slice(0,8) === "https://"));

        if (inner) {
            e.preventDefault();
        }

        if (menu.onClickBefore){
            await menu.onClickBefore(menuProperties);
        }

        if (menu.onClick){
            if (anchorEl !== null) {
                await setAnchorEl(null);
            }
            return menu.onClick(e, menuProperties);
        }

        if (inner){

            wapp.client.history.push({
                search:"",
                hash:"",
                ...wapp.client.history.parsePath((disableParentRoute) ? href : parentRoute + href)
            });

            if (anchorEl !== null) {
                setAnchorEl(null);
            }

        }
    }

    const actions = {
        close: handleMenuClose,
        setMenu: setMenu
    };

    useEffect(function () {
        if (effect){
            effect({
                actions
            })
        }
    });

    const featuredMenus = [...menu.filter(function (menu) {return !!(menu.featured && !list)})].sort(defaultSort);
    const showFeaturedMenu = [...featuredMenus.filter(function (menu) {return (menu.role) ? menu.role(menuProperties) : true})];

    const moreMenus = [...menu.filter(function (menu) {return !(menu.featured && !list)})].sort(defaultSort);
    const showMoreMenu = [...moreMenus.filter(function (menu) {return (menu.role) ? menu.role(menuProperties) : true})];

    const MenuComponent = (list) ? List : MaterialMenu;
    const ItemComponent = (list) ? ListItem : MenuItem;
    const menuComponentProps = (list) ? {} : {
        anchorEl,
        keepMounted: true,
        open:Boolean(anchorEl),
        onClose:handleMenuClose
    };

    const generateMenuProps = {ItemComponent, menuComponentProps, parentRoute, menuProperties, onClick, style, materialStyle, appContext, context, store};

    return (
        <>
            {
                (showFeaturedMenu.length) ?
                    [...featuredMenus.map(function (menu, key) {
                        const target = menu.target || "self";
                        const href = (typeof menu.href == "function") ? menu.href(menuProperties) : (menu.href) ? menu.href : "";
                        const Icon = menu.Icon;
                        const show = (menu.role) ? menu.role(menuProperties) : true;
                        const onlyIcon = Icon && menu.onlyIcon;
                        const inner = !(target === "_blank" || (href && href.slice(0,7) === "http://") || (href && href.slice(0,8) === "https://"));
                        const Element = menu.Element;
                        const name = (typeof menu.name == "function") ? menu.name(menuProperties) : menu.name;
                        const disableParentRoute = menu.disableParentRoute;

                        if (show) {

                            if (Element){
                                return (
                                    <Element key={"featured"+key} />
                                )
                            }

                            if (onlyIcon){

                                return (
                                    <IconButton
                                        key={"featured"+key}
                                        component={"a"}
                                        color={"inherit"}
                                        onClick={async function (e) {await onClick(e, menu);}}
                                        href={(inner) ? (disableParentRoute) ? href : parentRoute + href : href}
                                    >
                                        {(Icon) ? <Icon/> : null}
                                    </IconButton>
                                )

                            }

                            return (
                                <Button
                                    key={"featured"+key}
                                    component={"a"}
                                    color={"inherit"}
                                    onClick={async function (e) {await onClick(e, menu);}}
                                    href={(inner) ? (disableParentRoute) ? href : parentRoute + href : href}
                                    variant={"text"}
                                    startIcon={(Icon) ? <Icon/> : null}
                                >
                                    {name}
                                </Button>
                            )

                        }

                        return null;
                    })]
                    :
                    null
            }
            {
                (showMoreMenu.length > 0) ?
                    <>
                        {(!list) ?
                            <IconButton
                                className={style.menu}
                                color={"inherit"}
                                onClick={handleMenuOpen}
                                aria-controls={"post-menu"}
                                aria-haspopup={"true"}
                                aria-label={"post-menu"}
                            >
                                <MoreIcon />
                            </IconButton>
                            : null
                        }
                        <MenuComponent
                            className={clsx(materialStyle.menu, style.menu)}
                            id={"post-menu"}
                            {...menuComponentProps}
                        >
                            {[...moreMenus.map((m, k)=><GenerateMenu menu={m} key={"menu_item_" + k} {...generateMenuProps} menuKey={menuKey+"_"+k}/>)]}
                        </MenuComponent>
                    </>
                    :
                    null
            }
        </>
    )

}

const StyledComponent = withMaterialStyles(materialStyle, Menu);

export default React.memo(
    StyledComponent,
    (prevProps, nextProps)=> {
        let changes = (Object.keys(prevProps).join("|") !== Object.keys(nextProps).join("|"));
        if (!changes && prevProps.menuProperties && nextProps.menuProperties) {
            changes = (Object.keys(prevProps.menuProperties).join("|") !== Object.keys(nextProps.menuProperties).join("|"));
            if (!changes) {
                changes = !!(Object.keys(prevProps.menuProperties).filter((key) => {
                    return prevProps.menuProperties[key] !== nextProps.menuProperties[key];
                }).length)
            }
        }
        if (prevProps.menuKey !== nextProps.menuKey ||
            prevProps.menu !== nextProps.menu ||
            prevProps.list !== nextProps.list ||
            prevProps.parentRoute !== nextProps.parentRoute ||
            prevProps.effect !== nextProps.effect ) {
            changes = true;
        }
        return !changes;
    }
)
