// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".sLXjK {\n    display: -ms-flexbox;\n    display: flex;\n    -ms-flex-align: center;\n        align-items: center;\n    -ms-flex-pack: center;\n        justify-content: center;\n    width: 100%;\n    height: 100%;\n    min-height: inherit;\n}\n\n.sLXjK ._3txdx {\n    -webkit-box-shadow: none;\n            box-shadow: none;\n    background-color: transparent;\n}\n\n.-jUqS {\n    margin-bottom: 128px;\n    padding-right: 0;\n    padding-left: 0;\n}\n\n._335Tn {\n    width: 100%;\n    overflow: hidden;\n}\n\n._335Tn._3EUjQ {\n    padding-top: 24px;\n}\n\n._1Zu5l {\n    overflow: hidden;\n    -o-text-overflow: ellipsis;\n       text-overflow: ellipsis;\n}\n\n._3nUMw {\n    overflow: hidden;\n    -o-text-overflow: ellipsis;\n       text-overflow: ellipsis;\n    margin-bottom: 12px;\n}\n\n._2dsan {\n    min-height: 128px;\n    padding: 32px;\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"notFound": "sLXjK",
	"appBar": "_3txdx",
	"container": "-jUqS",
	"titleContainer": "_335Tn",
	"withSubtitle": "_3EUjQ",
	"title": "_1Zu5l",
	"subtitle": "_3nUMw",
	"content": "_2dsan"
};
module.exports = ___CSS_LOADER_EXPORT___;
