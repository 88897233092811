// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._3ZWSO{}\n\n._1plkV{}\n\n._3dtv8{}\n\n._3hPcD{}\n\n@media (max-width:799px) {\n\n    ._3dtv8 {\n        min-height: 100%;\n    }\n\n    ._3dtv8 {\n        border-radius: 0;\n        max-width: 100%;\n        width: 100%;\n        margin: 0;\n        max-height: 100%;\n    }\n\n    ._3hPcD{\n        padding: 16px;\n    }\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"singup": "_3ZWSO",
	"dialog": "_1plkV",
	"dialogPaper": "_3dtv8",
	"dialogContent": "_3hPcD"
};
module.exports = ___CSS_LOADER_EXPORT___;
