// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._2J0zc {}\n\n._2J0zc._2Hi0P {\n    font-size: 14.4px;\n    font-size: 0.9rem;\n}\n\n._2J0zc._2gxt0{\n    font-size: 17.6px;\n    font-size: 1.1rem;\n}\n\n._2J0zc._26OV- {\n    font-size: 28px;\n    font-size: 1.75rem;\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"avatar": "_2J0zc",
	"small": "_2Hi0P",
	"list": "_2gxt0",
	"big": "_26OV-"
};
module.exports = ___CSS_LOADER_EXPORT___;
