// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._1kIkJ{\n    width: 100%;\n    height: auto;\n    position: fixed;\n    display: -ms-flexbox;\n    display: flex;\n    bottom: -100%;\n    left: 0;\n    z-index: 1201;\n    background-color: var(--bg-color);\n    -ms-flex-align: center;\n        align-items: center;\n    -ms-flex-pack: justify;\n        justify-content: space-between;\n    -webkit-box-shadow: 0 0 2px 0 var(--border-color);\n            box-shadow: 0 0 2px 0 var(--border-color);\n    -webkit-transition: all 800ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;\n    -o-transition: all 800ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;\n    transition: all 800ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;\n}\n\n._1kIkJ.BjWRS {\n    bottom: 0;\n}\n\n._1kIkJ > span {\n    padding: 16px;\n}\n\n._1kIkJ > span:first-child {\n    padding-right: 0;\n}\n\n._18Iso{}\n\n._2CW_Q{}\n\n._3Ahyz{}\n\n._3L7yJ{}\n\n@media (max-width:799px) {\n\n    ._3Ahyz {\n        min-height: 100%;\n    }\n\n    ._3Ahyz {\n        border-radius: 0;\n        max-width: 100%;\n        width: 100%;\n        margin: 0;\n        max-height: 100%;\n    }\n\n    ._3L7yJ{\n        padding: 16px;\n    }\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cookies": "_1kIkJ",
	"show": "BjWRS",
	"acceptButton": "_18Iso",
	"dialog": "_2CW_Q",
	"dialogPaper": "_3Ahyz",
	"dialogContent": "_3L7yJ"
};
module.exports = ___CSS_LOADER_EXPORT___;
