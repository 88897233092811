// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._17gJv{\n    width: 100%;\n    position: relative;\n    overflow: hidden;\n}\n\n._1_saW {\n    border-spacing: 0;\n    margin: 0;\n    padding: 8px;\n}\n\n._1_saW tr {\n\n}\n\n._1_saW tr:last-child {\n\n}\n\n._1_saW th {\n    vertical-align: top;\n    text-align: left;\n    padding: 6px 0;\n    line-height: normal;\n}\n\n._1_saW td {\n    vertical-align: inherit;\n    text-align: left;\n    padding: 6px 0 6px 16px;\n}\n\n._1_saW ._3x0bf, ._1_saW .Dkf_3 {\n    width: 100%;\n    display: grid;\n}\n\n._2MhGP {\n    display: -ms-flexbox;\n    display: flex;\n    -ms-flex-align: center;\n        align-items: center;\n}\n\n._2MhGP ._3Cbd8 {}\n\n._2MhGP ._3Cbd8 > span {\n    word-break: keep-all;\n    white-space: nowrap;\n    -o-text-overflow: ellipsis;\n       text-overflow: ellipsis;\n    overflow: hidden;\n}\n\n._2MhGP ._1rb1p {\n    margin-right: 8px;\n    display: -ms-flexbox;\n    display: flex;\n    -ms-flex-align: center;\n        align-items: center;\n}\n\n._7Gvo- {\n    word-break: keep-all;\n    white-space: nowrap;\n    -o-text-overflow: ellipsis;\n       text-overflow: ellipsis;\n    overflow: hidden;\n}\n\n._7Gvo-._2DPGr {\n    word-break: normal;\n    white-space: normal;\n}\n\n._7Gvo-._2DPGr._3KjZp {\n    display: -webkit-box;\n    /*noinspection CssUnknownProperty*/\n    -webkit-line-clamp: 2;\n    -webkit-box-orient: vertical;\n    overflow: hidden;\n}\n\n._7Gvo- ._1BNnS{\n    margin-left: 3px;\n}\n\n._3_QxA {\n    word-break: keep-all;\n    white-space: nowrap;\n    -o-text-overflow: ellipsis;\n       text-overflow: ellipsis;\n    overflow: hidden;\n}\n\n._3uwKz {\n    word-break: keep-all;\n    white-space: nowrap;\n    -o-text-overflow: ellipsis;\n       text-overflow: ellipsis;\n    overflow: hidden;\n}\n\n._1N_KW {\n    display: -ms-flexbox;\n    display: flex;\n}\n\n._1N_KW svg {\n\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tableContainer": "_17gJv",
	"table": "_1_saW",
	"thInner": "_3x0bf",
	"tdInner": "Dkf_3",
	"label": "_2MhGP",
	"labelText": "_3Cbd8",
	"icon": "_1rb1p",
	"textField": "_7Gvo-",
	"multiline": "_2DPGr",
	"maxRows": "_3KjZp",
	"textFieldEndFix": "_1BNnS",
	"status": "_3_QxA",
	"date": "_3uwKz",
	"checkbox": "_1N_KW"
};
module.exports = ___CSS_LOADER_EXPORT___;
