// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._1UpiJ{}\n\n._1sZs9 {}\n\n.WSH15{}\n\n.WSH15 > div{\n    width: 100%;\n    margin-bottom: 24px;\n}\n\nbutton._3Ah8E {\n    margin-left: auto;\n    margin-right: auto;\n    display: block;\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"settings": "_1UpiJ",
	"menu": "_1sZs9",
	"form": "WSH15",
	"logoutSubmit": "_3Ah8E"
};
module.exports = ___CSS_LOADER_EXPORT___;
